<template>
	<div class="intelligence" style="cursor:pointer">
		<header_public :navindex='2'></header_public>
		<div class="a_i_top"></div>
		<div class="a_i_top_text">人工智能<span> 适合人群</span></div>

		<div class="a_i_first">
			<div class="a_i_first_item">
				<img class="a_i_first_item_img" src="../../assets/images/ai/ai_first_img01.png" alt="">
				<div class="a_i_first_item_bg"></div>
				<p class="a_i_first_item_title">刚毕业想把握未来</p>
				<p class="a_i_first_item_text">想抓住未来方向的但又无从下手的迫切求知者</p>
			</div>
			<div class="a_i_first_item">
				<img class="a_i_first_item_img" src="../../assets/images/ai/ai_first_img02.png" alt="">
				<div class="a_i_first_item_bg"></div>
				<p class="a_i_first_item_title">有基础想寻求提升</p>
				<p class="a_i_first_item_text">正在从事人工智能、python开发等IT岗位,想寻求技术提升,追求更高薪</p>
			</div>
			<div class="a_i_first_item">
				<img class="a_i_first_item_img" src="../../assets/images/ai/ai_first_img03.png" alt="">
				<div class="a_i_first_item_bg"></div>
				<p class="a_i_first_item_title">想转行没有目标</p>
				<p class="a_i_first_item_text">受够了传统行业的迷茫和窘迫，想要改变生活的求变者</p>
			</div>
			<div class="a_i_first_item">
				<img class="a_i_first_item_img" src="../../assets/images/ai/ai_first_img04.png" alt="">
				<div class="a_i_first_item_bg"></div>
				<p class="a_i_first_item_title">热爱人工智能</p>
				<p class="a_i_first_item_text">喜欢互联网行业，追求新技术、新热点，想挑战高薪</p>
			</div>
		</div>
		<div class="a_i_btn" @click="kefu()">咨询个人职业规划</div>
		<div class="a_i_top_text">培训费用多少钱<span> 评估一下</span></div>
		<div class="a_i_top_des">根据个人情况，评估下学习费用，心里有底</div>
		<pinggu></pinggu>
		<div class="a_i_top_text">人工智能<span> 涉及领域</span></div>
		<div class="a_i_second">
			<div class="a_i_second_item">
				
				<img class="a_i_second_item_img" src="../../assets/images/ai/ai_second_img04.png" alt="">
				<div class="a_i_second_item_title">
					<img src="../../assets/images/ai/ai_blue.png" alt="">
					<span> 智能制造 </span>
					<img src="../../assets/images/ai/ai_blue.png" alt="">
				</div>
				<p class="a_i_second_item_text">工业4.0时代，智能制造解决方案助力制造企业持续改进流程、预防问题发生、优化运营时间，跳出空间限制彻底改造价值链，重塑制造企业核心竞争力！
				</p>
			</div>
			<div class="a_i_second_item">
				<img class="a_i_second_item_img" src="../../assets/images/ai/ai_second_img03.png" alt="">
				<div class="a_i_second_item_title">
					<img src="../../assets/images/ai/ai_blue.png" alt="">
					<span> 智能交通 </span>
					<img src="../../assets/images/ai/ai_blue.png" alt="">
				</div>
				<p class="a_i_second_item_text">航线规划、无人驾驶汽车、超速、行车不规范等行为整治。</p>
			</div>
			<div class="a_i_second_item">
				<img class="a_i_second_item_img" src="../../assets/images/ai/ai_second_img05.png" alt="">
				<div class="a_i_second_item_title">
					<img src="../../assets/images/ai/ai_blue.png" alt="">
					<span> 智能医疗 </span>
					<img src="../../assets/images/ai/ai_blue.png" alt="">
				</div>
				<p class="a_i_second_item_text">
					利用最先进的物联网技术，实现患者与医务人员、医疗机构、医疗设备之间的互动，逐步达到信息化。例：健康监测（智能穿戴设备）、自动提示用药时间、服用禁忌、剩余药量等的智能服药系统。</p>
			</div>
			<div class="a_i_second_item">
				<img class="a_i_second_item_img" src="../../assets/images/ai/ai_second_img06.png" alt="">
				<div class="a_i_second_item_title">
					<img src="../../assets/images/ai/ai_blue.png" alt="">
					<span> 智能农业 </span>
					<img src="../../assets/images/ai/ai_blue.png" alt="">
				</div>
				<p class="a_i_second_item_text">
					农业中已经用到很多的AI技术，农作物状态实时监控，物料采购，数据收集，灌溉，收获，销售等。通过应用人工智能设备终端等，大大提高了农牧业的产量，大大减少了许多人工成本和时间成本。</p>
			</div>
			
			<div class="a_i_second_item">
				<img class="a_i_second_item_img" src="../../assets/images/ai/ai_second_img02.png" alt="">
				
				<div class="a_i_second_item_title">
					<img src="../../assets/images/ai/ai_blue.png" alt="">
					<span> 智慧金融 </span>
					<img src="../../assets/images/ai/ai_blue.png" alt="">
				</div>
				<p class="a_i_second_item_text">股票证券的大数据分析、行业走势分析、投资风险预估等。</p>
			</div>
			
			
			<div class="a_i_second_item">
				<img class="a_i_second_item_img" src="../../assets/images/ai/ai_second_img01.png" alt="">
				<div class="a_i_second_item_title">
					<img src="../../assets/images/ai/ai_blue.png" alt="">
					<span> 智能家电 </span>
					<img src="../../assets/images/ai/ai_blue.png" alt="">
				</div>
				<p class="a_i_second_item_text">家电智慧通过物联网技术，家中所有的智能家电均能互联互通，实现全屋互联。大到智慧客厅、智慧厨房等人工智能解决方案,小到智能机器人、智能音箱等硬件。
				</p>
			</div>
			
		</div>
		<div class="a_i_btn" @click="kefu()">咨询个人职业规划</div>
		<div class="a_i_top_text">人工智能<span> 课程体系与价值</span></div>

		<div class="a_i_thrid">
			<div class="a_i_thrid_left">
				<div class="weightname ">市场价值</div>
				<div class="weighttext">单位：元</div>
				<div class="moneytext">20000</div>
				<div class="moneytext">15000</div>
				<div class="moneytext">10000</div>
				<div class="moneytext">5000</div>
			</div>
			<div class="a_i_thrid_right swiper-container">
				<div class="a_i_thrid_right_top swiper-wrapper">
					<div class="right_top_item swiper-slide">
						<p class="right_top_item_content">判断语句、循环语句、
							字符串、列表、元祖、字典、集合、文件、函数、面向对象等；针对零基础学员设计课程，特别设计线上图书管理系统等综合实战案例，意在培养学员逻辑分析能力和基础代码能力，为学员后期学习打下坚实基础；
						</p>
						<div class="right_top_item_block" style="width: 10rem;height: 10rem;background-color: #1fbbe5;">
							<img style="width: 80%;" src="../../assets/images/ai/ai_time.png" alt="">
							<img src="../../assets/images/ai/python.png" alt="">
						</div>
						<div class="right_top_item_title">Python基础</div>
					</div>
					<div class="right_top_item swiper-slide">
						<p class="right_top_item_content">内存管理机制、高阶函 数、装饰器、生成器、迭代器、线程、进程、网络通信、MySQL数据库实战；</p>
						<div class="right_top_item_block"
							style="width: 10rem;height: 12.5rem;background-color: #ffce1b;">
							<img style="width: 80%;" src="../../assets/images/ai/ai_time.png" alt="">
							<img src="../../assets/images/ai/python.png" alt="">
						</div>
						<div class="right_top_item_title">Python高级</div>
					</div>
					<div class="right_top_item swiper-slide">
						<p class="right_top_item_content">HTML、CSS、JavaScri pt、ES6、Vue.js、element-ui、Vue.js综合项目实战；</p>
						<div class="right_top_item_block" style="width: 10rem;height: 15rem;background-color: #4b94e9;">
							<img style="width: 80%;" src="../../assets/images/ai/ai_time.png" alt="">
							<img src="../../assets/images/ai/vue.png" alt="">
						</div>
						<div class="right_top_item_title">Vue.js实战</div>
					</div>
					<div class="right_top_item swiper-slide">
						<p class="right_top_item_content">Django框架基础、Djan goRESTframework框架基础、好听云音乐项目实战；</p>
						<div class="right_top_item_block"
							style="width: 10rem;height: 17.5rem;background-color: #05ccd1;">
							<img style="width: 80%;" src="../../assets/images/ai/ai_time.png" alt="">
							<img src="../../assets/images/ai/Django.png" alt="">
						</div>
						<div class="right_top_item_title">Django基础</div>
					</div>
					<div class="right_top_item swiper-slide">
						<p class="right_top_item_content">Git、单元测试、好又多 生鲜超市项目实战；</p>
						<div class="right_top_item_block"
							style="width: 10rem;height: 20.625rem;background-color: #6556d6;">
							<img style="width: 80%;" src="../../assets/images/ai/ai_time.png" alt="">
							<img src="../../assets/images/ai/Django.png" alt="">
						</div>
						<div class="right_top_item_title">Django高级</div>
					</div>
					<div class="right_top_item swiper-slide">
						<p class="right_top_item_content">Python爬虫、数据加载
							、数据解析、MongoDB数据库、多线程爬虫、增量式爬虫、Scrapy框架爬虫、数据大屏前台展示、数据分析、词云图；</p>
						<div class="right_top_item_block"
							style="width: 10rem;height: 23.75rem;background-color: #ff9615;">
							<img style="width: 80%;" src="../../assets/images/ai/ai_time.png" alt="">
							<img src="../../assets/images/ai/web crawler.png" alt="">
						</div>
						<div class="right_top_item_title">网络爬虫</div>
					</div>
					<div class="right_top_item swiper-slide">
						<p class="right_top_item_content">
							人脸识别、三方登陆（新浪微博、QQ、钉钉）、Redis分布式锁、Redis常见数据淘汰策略、Docker、无限级分类、瀑布流异步加载、商品排序算法、视频分片上传、分布式云存储、秒级别实时商品排行榜、个性化智能推荐系统构建、智能机器人客服系统；
						</p>
						<div class="right_top_item_block"
							style="width: 10rem;height: 27.5rem;background-color: #fc6e51;">
							<img style="width: 80%;" src="../../assets/images/ai/ai_time.png" alt="">
							<img src="../../assets/images/ai/computer.png" alt="">
						</div>
						<div class="right_top_item_title">机器学习</div>
					</div>

				</div>
				<div class="swiper-scrollbar"></div>
				<div class="a_i_thrid_right_bottom"></div>
			</div>
		</div>

		<div class="a_i_btn" @click="kefu()">获取完整课程大纲</div>
		<div class="a_i_top_text">人工智能<span> 教学模式</span></div>
		<div class="a_i_fourth">
			<img class="ai_fourth_img" src="../../assets/images/ai/ai_fourth.png" alt="人工智能">
			<p class="a_i_fourth_text">帮助企业解决“实操刚需”痛点，让学员与企业无缝对接</p>
			<img class="ai_fourth_img02" src="../../assets/images/ai/ai_fourth02.png" alt="人工智能">
		</div>
		<div class="a_i_btn" @click="kefu()">了解更多</div>

		<div class="vis_des_ninth">
			<div>
				<div class="f_e_environment_text">
					<p>积云教育独有“<span style="color: #f14549;">大学校园</span>”学习环境</p>
				</div>
				<huanjing></huanjing>
			</div>
		</div>
		<div class="a_i_btn" @click="kefu()">预约到校参观</div>
		<div class="vis_des_tenth">
			<div class="f_e_environment_text">
				<p>常见问题<span style="color: #f14549;">解答</span></p>
			</div>
			<div class="v_d_t_list_text">
				<div>
					<p><span class="text_circle">01</span>对年龄和学历有要求吗？</p><span class="text_answer"
						@click="kefu()">查看答案</span>
				</div>
				<div>
					<p><span class="text_circle">02</span>无基础能学吗？</p><span class="text_answer"
						@click="kefu()">查看答案</span>
				</div>

				<div>
					<p><span class="text_circle">03</span>学费多少钱？能分期吗？</p><span class="text_answer"
						@click="kefu()">查看答案</span>
				</div>
				<div>
					<p><span class="text_circle">04</span>学多长时间？怎么上课？</p><span class="text_answer"
						@click="kefu()">查看答案</span>
				</div>

				<div>
					<p><span class="text_circle">05</span>好学吗？学不会怎么办？</p><span class="text_answer"
						@click="kefu()">查看答案</span>
				</div>
				<div>
					<p><span class="text_circle">06</span>学校在哪里？住宿是怎么安排？</p><span class="text_answer"
						@click="kefu()">查看答案</span>
				</div>

				<div>
					<p><span class="text_circle">07</span>学完能拿到大专学历吗？</p><span class="text_answer"
						@click="kefu()">查看答案</span>
				</div>
				<div>
					<p><span class="text_circle">08</span>学完是怎么安排就业的？</p><span class="text_answer"
						@click="kefu()">查看答案</span>
				</div>
			</div>
		</div>



		<bottom_public></bottom_public>
	</div>
</template>

<script>
	import Swiper from "swiper/js/swiper.min.js";
	import "swiper/css/swiper.min.css";
	import bottom_public from "../../components/bottom_public/bottom_public";
	import header_public from "../../components/header_public/header_public";
	import pinggu from '../../components/pinggu/pinggu';
	import huanjing from "../../components/huanjing/huanjing";

	export default {
		metaInfo: {
			title: "积云教育-人工智能培训|Python培训",
			meta: [{
					name: "description",
					content: "积云教育通过大数据分析结合市场岗位需求，设置体系完善的人工智能Python培训课程，为市场输送大批高精尖IT教育人工智能Python方向人才。",
				},
				{
					name: "keywords",
					content: "人工智能培训,Python培训,Python开发培训,Python开发",
				},
			],
		},
		data() {
			return {
				teacherlist: "",
				projectlist: "",
				stumessage: "",
				videoSrc: "",
				videoState: false,
				imgSrc: "",
				imgState: false,
				majorvideo: "",
			};
		},
		//在这里调用ajax请求方法
		created() {

		},
		methods: {
			kefu() {
				window.mantis.requestChat()
			},
		},
		components: {
			bottom_public,
			header_public,
			pinggu,
			huanjing,

		},
		mounted() {
			new Swiper('.swiper-container', {
				slidesPerView: "auto",
				resistanceRatio: 0,
				scrollbar: {
					el: '.swiper-scrollbar',
					draggable: true,
					snapOnRelease: false,
					dragSize: 30,
				}
			});

		},
	};
</script>

<style scoped>
	@import url("../../assets/css/intelligence/intelligence.css");
</style>
